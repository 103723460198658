<template>
  <div>
    <v-progress-circular
      v-if="loading"
      :size="10"
      :width="20"
      color="red"
      indeterminate
    />
  </div>
</template>
<script>
import axios from 'axios'
import utils from '@/services/utils'
export default {
  name: 'Acttiv',
  props: {
    hotel: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true
    },
    onChange: {
      type: Function,
      required: true,
    },
    folder: {
      type: String,
      required: true,
    },
    lmu: {
      type: String,
      default: null
    },
  },
  data: () => ({
    loading: true,
    titleDate: null,
    titleInfo: null,
    pages: null,
    activities: null,
    imagesNotFound: [],
    imagesFound: []
  }),
  watch: {
    day () {
      this.init()
    },
    hotel () {
      this.init()
    },
    folder () {
      this.init()
    },
    lmu () {
      this.init()
    },
  },
  computed: {
    interval () {
      const aux = utils.getParameterByName('interval')
      return aux ? aux : 6000
    },
    width () {
      const aux = utils.getParameterByName('w')
      return aux ? aux | 0 : 1920
    },
    height () {
      const aux = utils.getParameterByName('h')
      return aux ? aux | 0 : 1080
    },
    isRow () {
      return utils.getParameterByName('type') === 'row'
    },
    isForceDate () {
      return utils.getParameterByName('fd') ? true : false
    },
    rows () {
      const aux = utils.getParameterByName('rows')
      return aux ? aux | 0 : 3
    },
    cols () {
      return utils.getParameterByName('cols') | 0
    },
    marginLeft () {
      return utils.getParameterByName('ml')
    },
    marginTop () {
      return utils.getParameterByName('mt')
    },
    color1 () {
      return utils.getParameterByName('c1') ? '#'.concat(utils.getParameterByName('c1')) : '#02443C'
    },
    color2 () {
      return utils.getParameterByName('c2') ? '#'.concat(utils.getParameterByName('c2')) : '#BF9D5A'
    },
    bgcolor () {
      return utils.getParameterByName('c5') ? '#'.concat(utils.getParameterByName('c5')) : null
    },
    zoom () {
      let aux = utils.getParameterByName('zoom')
      aux = aux && aux.length > 0 ? parseInt(aux) : null
      return aux ? aux / 100 : null
    },
    locale () {
      const aux = utils.getParameterByName('locale')
      return aux ? aux : 'es'
    },
    date() {
      const addDays = this.day//utils.getParameterByName('addDays')
      if (addDays) {
        let aux = new Date()
        aux.setDate(aux.getDate() + parseInt(addDays))
        return aux
      }
        
      let aux = utils.getParameterByName('date')
      if (!aux || aux.length === 0) aux = new Date()
      return aux
    },
    showAll () {
      return utils.getParameterByName('showAll')
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    initActtivApi () {
      this.imagesNotFound = []
      this.imagesFound = []
      this.loading = true
      if (this.marginLeft) document.getElementById('app').style.marginLeft = this.marginLeft.concat('px')
      if (this.marginTop) document.getElementById('app').style.marginTop = this.marginTop.concat('px')

      if (this.zoom) {
        document.body.style.zoom = this.zoom
        document.body.style.MozTransform = `scale(${this.zoom})`
        document.body.style.WebkitTransform = `scale(${this.zoom})`
        document.body.style.transformOrigin = 'left top'
      }
      if (this.bgcolor) document.body.style.backgroundColor = this.bgcolor
      if (this.hotel && this.getFirstDay()) {
        let date = new Date()
        date.setDate(date.getDate() + (this.day | 0))
        axios.get(`https://acttiv-api.dtouchwebapp.com/v1/public/pias/${this.hotel}/${date.toISOString().split('T').shift()}`)
          .then(response => {
            this.convertDataActtivApi (response.data)
          })
      }
    },
    init () {
      this.initActtivApi()
      /*
      this.imagesNotFound = []
      this.imagesFound = []
      this.loading = true
      if (this.marginLeft) document.getElementById('app').style.marginLeft = this.marginLeft.concat('px')
      if (this.marginTop) document.getElementById('app').style.marginTop = this.marginTop.concat('px')

      if (this.zoom) {
        document.body.style.zoom = this.zoom
        document.body.style.MozTransform = `scale(${this.zoom})`
        document.body.style.WebkitTransform = `scale(${this.zoom})`
        document.body.style.transformOrigin = 'left top'
      }
      if (this.bgcolor) document.body.style.backgroundColor = this.bgcolor
      if (this.hotel && this.getFirstDay()) this.acctivLogin()
      */
    },
    getIsToday() {
      if (this.isForceDate) return false
      return this.formatDate(this.date) === this.formatDate(new Date()) ? true : false
    },
    getIsTomorrow() {
      if (this.isForceDate) return false
      const aux = new Date()
      aux.setDate(aux.getDate() + 1)
      return this.formatDate(this.date) === this.formatDate(aux) ? true : false
    },
    formatDate(date, short) {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
    
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
    
      if (short) return [day, month].join('/')
      else return [year, month, day].join('-')
    },
    getFirstDay () {
      const d = new Date(this.date)
      const day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      return this.formatDate(new Date(d.setDate(diff)))
    },
    getDay () {
      if(this.locale === 'en') return this.getIsToday() ? ' TODAY ' : this.getIsTomorrow() ? ' TOMORROW ' : ' '
      else if(this.locale === 'de') return this.getIsToday() ? ' HEUTE ' : this.getIsTomorrow() ? ' MORGEN ' : ' '
      else if(this.locale === 'fr') return this.getIsToday() ? " AUJOURD'HUI " : this.getIsTomorrow() ? ' MATIN ' : ' '

      return this.getIsToday() ? ' HOY ' : this.getIsTomorrow() ? 'MA�ANA' : ' '
    },
    getIsActive(st, et) {
      if (this.isForceDate) return false
      return st <= new Date() && et >= new Date()
    },
    getIsOld(value) {
      if (this.isForceDate) return false
      return value < new Date() ? true : false
    },
    acctivLogin () {
      const body = {
        login: 'Descubre',
        //pass: 'LSqfnVdFuiA'
        pass: '12345'
      }

      axios.put('https://intranet.acttiv.es/api-pias/v1/session/login', body, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (response && response.data.token) {
          const token = response.data.token
          this.acttivPias(token)
        }
      })
      .catch(error => {
        this.loading = false
        console.log('err', error)
      })
    },
    acttivLogout (token) {
      axios.delete('https://intranet.acttiv.es/api-pias/v1/session/logout', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      .then(response => {
        this.loading = false
        if (response.status === 201) console.log('Logout success') 
      })
      .catch(error => console.log('err', error))
    },
    acttivPias (token) {
      const url = `https://intranet.acttiv.es/api-pias/v1/pias/pia?idHotel=${this.hotel}&fecha=${this.getFirstDay()}`
      axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      .then(response => {
        if (response && response.status === 200  && response.data) {
          this.pages = this.convertPiasData(response.data)
        }
        this.acttivLogout(token)
      })
      .catch(error => {
        this.acttivLogout(token)
        console.log('err', error)
      })
    },
    urlExists(url) {
      try {
        const http = new XMLHttpRequest()
        http.open('HEAD', url, false)
        http.send()
        return http.status != 404
      } catch {
        return false
      }
    },
    replaceAll(string, search, replace) {
      return string ? string.split(search).join(replace) : ''
    },
    prepareImage(v) {
      let url = `https://www.dviewcontent.com/activity/acttiv/images/${this.folder ? this.folder : 'default'}/${this.formatImageName(v.nombre_tarea)}.jpg`
      
      if (!this.urlExists(url)) {
        if (this.imagesNotFound.filter(x => x.name === this.formatImageName(v.nombre_tarea)).length ===0)
          this.imagesNotFound.push({ folder: this.folder, url, type: 'tarea', name: this.formatImageName(v.nombre_tarea), ext: 'jpg' })
        url = `https://www.dviewcontent.com/activity/acttiv/images/${this.folder ? this.folder : 'default'}/${v.codigo_categoria_actividad}.jpeg`
      } else {
        if (this.imagesFound.filter(x => x.name === this.formatImageName(v.nombre_tarea)).length ===0)
          this.imagesFound.push({ folder: this.folder, url, type: 'tarea', name: this.formatImageName(v.nombre_tarea), ext: 'jpg' })
      }
      const isPos20 = (new Date(v.hora_inicio).toTimeString().slice(0, 2) | 0) >= 20
      if (!this.urlExists(url) && isPos20) {
        if (this.imagesNotFound.filter(x => x.name === v.codigo_categoria_actividad).length ===0)
          this.imagesNotFound.push({ folder: this.folder, url, type: 'cat', name: v.codigo_categoria_actividad, ext: 'jpeg'  })
        url = `https://www.dviewcontent.com/activity/acttiv/images/${this.folder ? this.folder : 'default'}/404.jpg`
      } else {
        if (this.imagesFound.filter(x => x.name === v.codigo_categoria_actividad).length ===0)
          this.imagesFound.push({ folder: this.folder, url, type: 'cat', name: v.codigo_categoria_actividad, ext: 'jpeg'  })
      }
      return url
    },
    formatImageName (value) {
      let aux = value
      aux = this.replaceAll(aux, ' ', '')
      aux = this.replaceAll(aux, '-', '')
      aux = this.replaceAll(aux, '_', '')
      aux = this.replaceAll(aux, '�', 'a')
      aux = this.replaceAll(aux, '�', 'e')
      aux = this.replaceAll(aux, '�', 'i')
      aux = this.replaceAll(aux, '�', 'o')
      aux = this.replaceAll(aux, '�', 'u')
      aux = this.replaceAll(aux, '�', 'n')
      return aux.toLowerCase()
    },
    convertDataActtivApi (v) {
      for (let item of v) {
        const tar = JSON.parse(item.tar)
        this.prepareImage(tar)
      }
      this.onChange(this.imagesNotFound, this.imagesFound)

      this.loading = false
      return
    },
    convertPiasData (pia) {
      if (this.hotel === '1460') pia.nombre_hotel = pia.nombre_hotel.replace('Esmeralda Park', '')
      this.titleInfo = pia.nombre_hotel
      const d = new Date(this.date)
      this.titleDate = `${this.getDay()} ${d.getDate()}/${d.getMonth()+1}`

      let result = []
      for (const program of pia.programa_hotel) {
        for (const plan of program.dia_planif) {
          for (const tar of plan.tarea_pia) {
            const name = {
              es: tar.nombre_tarea_es ? tar.nombre_tarea_es : tar.nombre_tarea,
              en: tar.nombre_tarea_en ? tar.nombre_tarea_en : tar.nombre_tarea,
              de: tar.nombre_tarea_de ? tar.nombre_tarea_de : tar.nombre_tarea,
              fr: tar.nombre_tarea_fr ? tar.nombre_tarea_fr : tar.nombre_tarea,
            }
            const detail = {
              es: program.nombre_programa,
            }
            const startTime = new Date(tar.hora_inicio)
            const endTime = new Date(tar.hora_fin)
            //if (tar.nombre_tarea = 'Turo Disco')

            let subtitle = tar.nombre_categoria_actividad
            if (tar.lugar && tar.lugar !== '') subtitle = tar.lugar 
            if (tar.punto_encuentro && tar.punto_encuentro !== '') subtitle = tar.punto_encuentro


            if (plan.fecha === this.formatDate(this.date)) {
              if(this.showOld || !this.getIsOld(endTime))
                result.push({
                  programID: program.id_programa_hotel,
                  programName: program.nombre_programa,
                  title: name[this.locale] ? name[this.locale] : tar.nombre_tarea,
                  image: this.prepareImage(tar),
                  time: `${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`,
                  subtitle,
                  detail: detail[this.locale] ? detail[this.locale] : null,
                  active: this.getIsActive(startTime, endTime),
                  old: this.getIsOld(endTime),
                  start: startTime,
                })
            }
          }
        }
      }
      this.onChange(this.imagesNotFound, this.imagesFound)

      this.loading = false
      return
    },
  },
}
</script>

<style scoped>
#app {
  background: none;
}
</style>


