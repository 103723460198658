<template>
  <div style="padding: 20px;">
    <v-layout wrap>
      <v-flex xs12>
        <a :href="getActivityPlayer(selectedDay, selectedCode, actualFolder)">
        <small>
          {{ getActivityPlayer(selectedDay, selectedCode, actualFolder)}}
        </small>
          </a>
      </v-flex>
      <v-flex xs12>
        <a :href="getWeHappy(selectedDay, selectedCode)">
        <small>
        {{ getWeHappy(selectedDay, selectedCode)}}
        </small>
          </a>
      </v-flex>
      <v-flex xs6>
        <v-select
          :items="[350,2535]"
          label="Hotel"
          v-model="selectedCode"
        />
      </v-flex>
      <v-flex xs6>
        <v-select
          :items="[0,1,2,3,4,5,6,7,8,9,10]"
          label="Day"
          v-model="selectedDay"
        />
      </v-flex>
      <v-flex xs12>
      <check-images 
        :hotel="selectedCode.toString()"
        :folder="actualFolder"
        :day="selectedDay.toString()"
        :onChange="handleChangeImagesNotFound"
        :lmu="lmu"
      />
      </v-flex>
      <v-flex xs6>
        <p
          v-for="(item) of notFound"
          :key="item.name"
        >
        <a @click="handleEdit(item)">[{{item.type}} - {{item.folder}}] {{item.name}}</a>
        </p>
        <p
          v-for="(item) of found.filter(x => x && x.name && x.type && x.folder)"
          :key="item.name"
        >
          <a @click="handleEdit(item)"
          style="color: red;"
            >[{{item.type}} - {{item.folder}}] {{item.name}}</a>
        </p>
      </v-flex>
      <!--v-flex xs6>
        <p
          v-for="(item) of notFound"
          :key="item.ID"
        >
          <a @click="handleEdit(item.ID)">{{item.ID}}</a>
        </p>

        <p
          v-for="(item) of found.filter(x => `${x.id}${x.tag}`.indexOf('opia') < 0 )"
          :key="`${item.id}${item.tag}`"
        >
        <a @click="handleExistEdit(item)" style="color: red;">{{item.id}} [{{item.tag}}]</a>
        </p>
      </v-flex-->
      <v-flex xs6>
        <acttiv-image-form 
          v-if="editedID" 
          :editedID="editedID" 
          :onClose="handleClose"
          :actualURL="actualURL"
          :item="actualItem"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ActtivImageForm from './ActtivImageForm'
import CheckImages from './CheckImages'
export default {
  components: {
    ActtivImageForm,
    CheckImages,
  },
  data: () => ({
    selectedCode: 350,
    selectedDay: 0,
    notFound: [],
    found: [],
    lmu: null,
    editedID: null,
    actualURL: null,
    actualItem: null,
    actualFolder: 'barcelonew',
  }),
  watch: {
    selectedDay () {
      this.notFound = []
      this.found = []
    },
  },
  methods: {
    getActivityPlayer (day, hotel, folder) {
      return `https://www.dviewcontent.com/activity/player/?zoom=50&addDays=${day}&c1=3C3C3B&c2=d12726&c5=ffffff00&ml=60&mt=-5&hotel=${hotel}&folder=${folder}&locale=es`
    },
    getWeHappy (day, hotel) {
      return `https://www.dviewcontent.com/activity/acttiv_barcelo/?hotel=${hotel}&AddDays=${day}`
    },
    handleEdit (v) {
      this.actualItem = v
      this.actualURL = v.url
      this.editedID = v.url
    },
    handleClose (refresh) {
      this.actualURL = null
      this.editedID = null
      if(refresh) this.lmu = new Date().toISOString()
    },
    handleChangeImagesNotFound (v, v2) {
      this.notFound = v
      this.found = v2.filter(x => x.name && x.name.length > 0)
    },
  },
}
</script>
